export default class RelatorioService {
  constructor(http) {
    this._http = http
  }

  async gerarRelatorioViaJasper(relatorio) {
    let formData = new FormData()

    relatorio.parametros = Object.fromEntries(relatorio.parametros)
    formData.append('jsonRelatorioDto', JSON.stringify(relatorio))

    const { data } = await this._http.post(`/api/relatorio`, formData, {
      responseType: 'blob',
    })

    return data
  }

  async gerarRelatorioViaHtml(relatorio) {
    let formData = new FormData()

    relatorio.parametros = Object.fromEntries(relatorio.parametros)
    formData.append('jsonRelatorioDto', JSON.stringify(relatorio))

    const { data } = await this._http.post(
      `/api/relatorio/relatorioViaHtml`,
      formData,
    )

    return data
  }

  async getListaResponsaveis() {
    const { data } = await this._http.get(
      `/api/relatorio/listaResponsavelRelatorioTaxaAssistencial`,
    )

    return data
  }
}
