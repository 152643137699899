export default class Relatorio {
  constructor(
    tipo = '',
    nome = '',
    conexao = '',
    contentType = 'PDF',
    parametros = new Map(),
  ) {
    this.tipo = tipo
    this.nome = nome
    this.contentType = contentType
    this.parametros = parametros
    this.conexao = conexao
  }
}
