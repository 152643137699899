<template>
  <div class="grid">
    <!-- <div class="col-11 div-center">
      <Card>
        <template #title> Ficha Financeira </template>
<template #content>
          A ficha financeira está passando por uma atualização para aprimorar o
          serviço oferecido a você, cidadão. Estamos trabalhando para servi-lo
          melhor e retornaremos em breve.!
        </template>
      </Card>
    </div> -->
    <div class="col-11 div-center">
      <Card>
        <template #title> Ficha Financeira </template>
        <template #content>
          <div class="p-fluid grid">
            <!--<div class="field col-12 md:col-6">
              <span class="p-float-label">
                <Dropdown
                  v-model="matricula"
                  :options="matriculasList"
                  data-key="id"
                  placeholder="Matrícula"
                  style="width: 100%"
                  @change="carregarCombos()">
                  <template #value="slotProps">
                    <div v-if="slotProps.value">
                      <span>
                        {{ slotProps.value.matricula }} -
                        {{ slotProps.value.orgaoNome }}
                      </span>
                    </div>
                  </template>
                  <template #option="slotProps">
                    <span class="card-desktop">
                      {{ slotProps.option.matricula }} -
                      {{ slotProps.option.orgaoNome }}
                    </span>
                    <span class="card-mobile">
                      {{ slotProps.option.matricula }} -
                      {{ slotProps.option.orgaoNome | limitadorString }}
                    </span>
                  </template>
                </Dropdown>
                <label for="matricula">Matrícula</label>
              </span>
            </div>-->
            <div v-if="folhasList.length > 1" class="field col-12 md:col-2">
              <span class="p-float-label">
                <Dropdown v-model="folha" :options="folhasList" />
                <label for="folha">Folha</label>
              </span>
            </div>
            <div class="field col-12 md:col-2">
              <span class="p-float-label">
                <Dropdown v-model="exercicio" :options="exerciciosList" />
                <label for="exercicioFim">Exercício</label>
              </span>
            </div>
            <div class="field col-12 md:col-2" hidden>
              <span class="p-float-label">
                <Dropdown
                  v-model="exercicioInicio"
                  :options="exerciciosInicioList"
                  @change="listarExericiosFimESelecionarExericioFim()" />
                <label for="exercicioInicio">Exercício Início</label>
              </span>
            </div>

            <div class="field col-12 md:col-2" hidden>
              <span class="p-float-label">
                <Dropdown v-model="exercicioFim" :options="exerciciosFimList" />
                <label for="exercicioFim">Exercício Fim</label>
              </span>
            </div>
            <div class="field col-12 md:col-2">
              <Button
                label="Gerar Ficha"
                :disabled="exercicio == null"
                @click="gerarFichaFinanceira()" />
            </div>
          </div>
        </template>
      </Card>
    </div>
    <div class="col-11 div-center">
      <Card v-if="pdfBlobUrl">
        <template #content>
          <div class="field col-12 md:col-12">
            <object
              :data="pdfBlobUrl"
              type="application/pdf"
              width="100%"
              height="600">
              <Button
                label="Download PDF"
                style="width: 100%"
                @click="downloadPdf()" />
            </object>
          </div>
        </template>
      </Card>
    </div>
  </div>
</template>
<script>
import { saveAs } from 'file-saver'
import MatriculaService from '@/service/MatriculaService.js'
import Relatorio from '@/domain/Relatorio.js'
import RelatorioService from '@/service/RelatorioService.js'
import FichaFinanceiraService from '@/service/FichaFinanceiraService.js'
import { selecionarObjetoPadrao } from '@/utils/SelecionarObjetoPadrao'
import { matriculaStore } from '@/stores/matricula'

export default {
  setup() {
    const store = new matriculaStore()
    return { store }
  },

  data() {
    return {
      relatorio: new Relatorio(),
      matriculasList: [],
      exerciciosList: [],
      exerciciosInicioList: [],
      exerciciosFimList: [],
      folhasList: [],
      exercicio: null,
      exercicioInicio: null,
      exercicioFim: null,
      folha: null,
      matricula: null,
      loading: false,
      totalRecords: 0,
      lazyParams: {},
      loadingDetail: false,
      loadingPdf: false,
      pdfBlobUrl: null,
      pdfBlob: null,
    }
  },

  computed: {
    rowsPerPageOptions() {
      if (this.totalRecords < 5) {
        return null
      }
      return [5, 10, 25]
    },
  },

  watch: {
    'store.matriculaSelecionada': function () {
      this.carregarDados()
    },
  },

  created() {
    this.matriculaService = new MatriculaService(this.$http)
    this.relatorioService = new RelatorioService(this.$http)
    this.fichaFinanceiraService = new FichaFinanceiraService(this.$http)
  },

  mounted() {
    this.loading = true
    this.carregarDados()
  },
  beforeDestroy() {
    URL.revokeObjectURL(this.pdfBlobUrl)
  },

  methods: {
    listarExericiosFimESelecionarExericioFim() {
      this.exerciciosFimList = this.exerciciosInicioList.filter(
        (year) => year >= this.exercicioInicio,
      )
      this.exercicioFim = this.exerciciosInicioList.find(
        (year) => year == this.exercicioInicio,
      )
    },
    beforeDestroy() {
      URL.revokeObjectURL(this.pdfBlobUrl)
    },
    downloadPdf() {
      saveAs(this.pdfBlob, 'FICHA_FINANCEIRA.pdf')
    },
    gerarFichaFinanceira() {
      this.fichaFinanceiraService
        .gerarFichaFinanceira(
          this.store.matriculaSelecionada.id,
          this.exercicio,
        )
        .then((res) => {
          const blob = new Blob([res], {
            type: 'application/pdf',
          })
          this.pdfBlob = blob
          this.pdfBlobUrl = URL.createObjectURL(blob)

          this.relatorio.parametros = new Map()
          this.loadingPdf = false
        })
        .catch((err) => {
          this.showToastErro(err)
        })
      //   this.relatorio.tipo = 'FICHAFINANCEIRA'
      //   this.relatorio.conexao = 'CONEXAO_PORTAL_SERVIDOR_FOLHA_DIRETA'
      //   this.relatorio.nome = 'FICHA_FINANCEIRA'
      //   this.relatorio.contentType = 'PDF'
      //   this.relatorio.parametros = new Map()
      //   this.relatorio.parametros.set('MATRICULA', this.matricula.matricula)
      //   this.relatorio.parametros.set('FOLHA', this.folha)
      //   this.relatorio.parametros.set('EXERCICIO', this.exercicio)
      //   this.relatorio.parametros.set('EXERCICIO_INICIO', this.exercicioInicio)
      //   this.relatorio.parametros.set('EXERCICIO_FIM', this.exercicioFim)
      //   this.loadingPdf = true
      //   this.relatorioService
      //     .gerarRelatorioViaJasper(this.relatorio)
      //     .then((res) => {
      //       //this.downloadPdf(res, this.relatorio.nome)
      //       const blob = new Blob([res], {
      //         type: 'application/pdf',
      //       })
      //       this.pdfBlob = blob
      //       this.pdfBlobUrl = URL.createObjectURL(blob)

      //       this.relatorio.parametros = new Map()
      //       this.loadingPdf = false
      //     })
    },

    carregarDados() {
      this.loading = true
      this.matricula = this.store.matriculaSelecionada
      this.carregarCombos()
      this.limpar()
      /*this.matriculaService
        .getMatriculasUsuarioLogado()
        .then((res) => {
          this.matriculasList = res
          this.matricula = this.matriculasList[0]
          this.carregarCombos()
          this.limpar()
        })
        .catch((err) => {
          const mensagem = err.response
            ? err.response.data.message
            : 'Erro de conexão com a aplicação.'
          this.limpar()
          this.$toast.add({
            severity: 'error',
            summary: mensagem,
            life: 10000,
          })
          this.$router.push('/logout')
        })*/
    },

    carregarCombos() {
      this.carregarComboExercicio()
      this.carregarComboFolha()
    },

    carregarComboExercicio() {
      this.fichaFinanceiraService
        .getListaExercicios(this.matricula.id)
        .then((res) => {
          this.exerciciosList = res
          this.exerciciosInicioList = res
          this.exerciciosFimList = res
        })
        .catch((err) => {
          this.showToastErro(err)
        })
    },

    carregarComboFolha() {
      this.fichaFinanceiraService
        .getListaFolhas(this.matricula.id)
        .then((res) => {
          this.folhasList = res
          this.folha = selecionarObjetoPadrao(this.folhasList)
        })
        .catch((err) => {
          const mensagem = err.response
            ? err.response.data.message
            : 'Erro de conexão com a aplicação.'
          this.limpar()
          this.$toast.add({
            severity: 'error',
            summary: mensagem,
            life: 10000,
          })
        })
    },

    limpar() {
      this.loading = false
    },

    showToastErro(err) {
      const mensagem = err.response
        ? err.response.data.message
        : 'Erro de conexão com a aplicação.'
      this.limpar()
      this.$toast.add({
        severity: 'error',
        summary: mensagem,
        life: 10000,
      })
    },
  },
}
</script>
<style>
.div-center {
  margin: auto;
}
</style>
